<template>
  <div>
    <p>
     {{page.inleiding}}
    </p>
  </div>
</template>





<script>


export default {
  name: "indienen",
   props: ["page"],
};
</script>

<style scoped>

p {
  color : black;
}

</style>